<template>
  <div>
    <VueSelect
      v-model="selectedCar"
      @search="fetchCars"
      :options="carSearchResults"
      :filterable="false"
      @input="chooseCar"
      placeholder="Add new car"
    ></VueSelect>
    <ul class="list-group mt-3">
      <li v-for="(userCar, index) in userCars" class="list-group-item" :key="'uc-' + index">
        <div class="float-left" style="line-height: 31px;">
          {{ userCar.car.model }} {{ userCar.car.color }} | {{ userCar.car.number }}
        </div>
        <div class="float-right">
          <button class="btn btn-link btn-sm text-primary">Use as current</button>
          <button @click="remove(userCar)" class="btn btn-link btn-sm text-danger">Remove</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import config from '@/config'

export default {
  name: 'UserCars',
  components: {
    VueSelect
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  mounted () {
    this.getData()
  },
  data () {
    return {
      selectedCar: null,
      carsSearch: [],
      userCars: []
    }
  },
  computed: {
    carSearchResults () {
      return this.carsSearch.map(function (item) {
        return {
          label: item.model + ' ' + item.color + ' | ' + item.number,
          id: item.id
        }
      })
    }
  },
  methods: {
    getData () {
      this.$axios.get(config.baseApiUrl + '/api/user-cars/' + this.userId)
        .then(response => {
          this.userCars = response.data
        })
    },
    remove (userCar) {
      this.$axios.delete(config.baseApiUrl + '/api/user-cars', {
        data: {
          user_id: userCar.user_id,
          car_id: userCar.car_id,
          type: userCar.type
        }
      })
        .then(() => {
          this.getData()
        })
    },
    fetchCars (search, loading) {
      loading(true)
      this.$axios.get(config.baseApiUrl + '/api/cars', {
        params: {
          search: search
        }
      })
        .then((response) => {
          this.carsSearch = response.data.data
          loading(false)
        })
    },
    chooseCar (car) {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/users-cars',
        data: {
          user_id: this.userId,
          car_id: car.id,
          type: 'driver',
          is_default: true
        }
      })
        .then(() => {
          this.selectedCar = null
          this.carsSearch = []
          this.getData()
        })
    }
  }
}
</script>

<style scoped>
  ul.list-group li {
    vert-align: middle;
  }
</style>
