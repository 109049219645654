<template>
  <div class="container-fluid">
    <router-link :to="'/users/' + this.$route.params.role" class="btn btn-info">&lt; Inapoi la lista</router-link>
    <div class="row">
      <div class="col">
        <form v-if="user" @submit.prevent="save">
          <div class="mb-3">
            <label>Name</label>
            <input v-model="user.name" type="text" minlength="5" class="form-control" />
          </div>
          <div class="mb-3">
            <label>Email</label>
            <input v-model="user.email" type="email" required minlength="5" class="form-control" />
          </div>
          <div class="mb-3">
            <label>Roles</label>
            <Multiselect v-model="user.roles" :multiple="true" :options="['admin', 'dispatcher', 'driver', 'client']"></Multiselect>
          </div>
          <div v-if="user.id === null" class="mb-3">
            <label>Parola</label>
            <input v-model="user.password" type="text" required minlength="4" class="form-control" />
          </div>
          <div class="mb-3">
            <label>Phone</label>
            <input v-model="user.phone" type="tel" class="form-control" />
          </div>
          <button type="submit" class="btn btn-success">SAVE</button>
        </form>
      </div>
      <div v-if="user.id && user.roles.includes('driver')" class="col">
        <h5>User cars</h5>
        <UserCars :user-id="user.id"></UserCars>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import UserCars from '@/components/users/UserCars'

export default {
  name: 'UserEdit',
  components: {
    UserCars,
    Multiselect
  },
  data () {
    return {
      user: {
        id: null,
        name: '',
        email: '',
        phone: '',
        roles: [],
        password: ''
      }
    }
  },
  mounted () {
    if (this.$route.params.id !== '0') {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.$route.params.id)
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    save () {
      this.$axios({
        method: this.$route.params.id === '0' ? 'post' : 'patch',
        url: config.baseApiUrl + '/api/users' + (this.$route.params.id === '0' ? '' : ('/' + this.$route.params.id)),
        data: this.user
      })
        .then((response) => {
          this.$toasted.success('Saved', {
            duration: 3000
          })
          if (this.$route.params.id === '0') {
            this.user = response.data
            this.$router.push({ name: 'UserEdit', params: { id: response.data.id } })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
