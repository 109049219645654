<template>
  <div>
    <b-jumbotron class="mt-4">
        <template #header>Lista {{ usersType }}</template>
        <hr class="my-4">
        <p>
            Acțiuni:
        </p>
        <b-button variant="light" :to="'/user/0/' + usersType">Adaugă {{ usersType }} nou</b-button>
    </b-jumbotron>
    <Vuetable
      ref="vuetable"
      :css="css.table"
      :api-url="apiUrl"
      :fields="tableFields"
      :http-fetch="fetchApiData"
      :transform="transformApiResponse"
      pagination-path="meta"
      @vuetable:pagination-data="onPaginationData"
    >
        <template slot="actions" slot-scope="props">
            <b-button-group class="mx-1">
                <b-button :to="'/user/' + props.rowData.id + '/' + props.rowData.roles" variant="warning"> Modifică</b-button>
                <b-button @click="remove(props.rowData.id)" variant="danger">Șterge</b-button>
            </b-button-group>
        </template>
    </Vuetable>
    <div class="text-center d-flex justify-content-center mt-3">
      <VuetablePagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></VuetablePagination>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import CssConfig from '@/assets/other/VuetableBootstrap4Config'

export default {
  name: 'UsersList',
  components: {
    Vuetable,
    VuetablePagination
  },
  props: {
    usersType: {
      type: String,
      required: true
    }
  },
  mounted () {},
  data () {
    return {
      tableFields: [
        {
          name: 'id',
          title: 'ID'
        },
        {
          name: 'name',
          title: 'Nume'
        },
        {
          name: 'email',
          title: 'Email'
        },
        {
          name: 'phone',
          title: 'Telefon'
        },
        {
          name: 'roles',
          title: 'Rol',
          formatter (value) {
            return value.join(', ')
          }
        },
        {
          name: 'created_at',
          title: 'Data creării',
          formatter (value, component) {
            return component.$day(value).format('DD.MM.YYYY HH:mm')
          }
        },
        {
          name: 'actions',
          title: 'Edit'
        }
      ],
      css: CssConfig
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/users?role=' + this.usersType
    }
  },
  methods: {
    fetchApiData (apiUrl, httpOptions) {
      return this.$axios.get(apiUrl, httpOptions)
    },
    transformApiResponse (response) {
      return response
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    remove (id) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/users/' + id)
          .then(() => {
            this.getData()
          })
      }
    }
  },
  watch: {
    usersType () {
      this.getData()
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
